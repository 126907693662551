import React, { useEffect, useMemo, useState } from 'react';
import { AppScreen } from '../../../components/containers/AppScreen';
import { useDispatch } from 'react-redux';
import { t } from '../../../services/translations';
import { RefreshControl, View, ActivityIndicator } from 'react-native';
import { setLoading } from '../../../store/action';
import { SearchTextInput } from '../../../components/atomic/SearchTextInput';
import { AppListSwitch } from '../../../components/atomic/AppListSwitch';
import { isAdmin, isMobile } from '../../../services/utils';
import AppText from '../../../components/atomic/AppText';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../../styles/appColor';
import { ContributionType, ContributionTypesInfos } from '../../../types';
import { AppModalOverlay } from '../../../components/atomic/AppModalOverlay';
import { ContributionItemRowCarPool } from '../../../components/atomic/ContributionItemSquare';
import { AppMapView } from '../../../components/atomic/AppMapView';
import { GetContributionsMode } from '../../../services/api/getContributionsMode';
import { resolveContributionType } from '../../../services/api/contributions';
import { ContributionKiosque } from '../../../entities/ContributionKiosque';
import { Category } from '../../../entities/Category';
import { getCategoriesByType } from '../../../services/api/category.api';
import { hasCategories } from '../../../utils/Category';
import { CategoriesList } from '../../../components/containers/CategoriesList';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import usePaginatedList from '../../../hooks/usePaginatedList';
import { getContributionsList } from '../../../services/api/contribution.api';
import { ResponsiveFlatList } from '../../../components/atomic/ResponsiveFlatList';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import useLoggedUser from '../../../hooks/useLoggedUser';
import useContributionListRefresh from '../../../hooks/useContributionListRefresh';

export function ContributionsMainScreen(props: {
  contributionType: ContributionType;
  contributionMode: GetContributionsMode;
  contributionModeSecondView?: GetContributionsMode;
  navigation: NavigationProp<ParamListBase>;
  numColumns?: number;
  isAdminView: boolean;
  hideCategory?: boolean;
  isSignaled?: boolean;
  getRenderItem: (item: any, contributionMode: GetContributionsMode, onPress: (item: any) => void) => JSX.Element;
  onPress: (item: any) => void;
}) {
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const [showLost, setShowLost] = useState(false);
  const [showReported, setShowReported] = useState(props.isSignaled ?? false);
  const [showMap, setShowMap] = useState(false);
  const [search, setSearch] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<undefined | Category>(undefined);
  const [displaySecondView, setDisplaySecondView] = useState(false);
  const [filterFavorites, setFilterFavorites] = useState(false);
  const { isSmallMediumDevice } = useDeviceSize();

  const {
    data: items,
    isLoading,
    isRefreshing,
    fetchNext,
    refresh,
  } = usePaginatedList<any, string>(
    async (cursor: string | null) => {
      return getContributionsList(
        user,
        !displaySecondView ? props.contributionMode : props.contributionModeSecondView || props.contributionMode,
        props.contributionType,
        search,
        cursor,
        undefined,
        filterFavorites
      );
    },
    [
      search,
      displaySecondView,
      props.contributionMode,
      props.contributionModeSecondView,
      props.contributionType,
      filterFavorites,
    ]
  );

  useEffect(() => {
    if (hasCategories(props.contributionType))
      getCategoriesByType(user.entreprise?.id!, props.contributionType, user.token!).then(setCategories);
  }, []);

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isRefreshing));
  }, [isRefreshing, dispatch]);

  // Refresh after an update
  useContributionListRefresh(refresh);

  function AppContributionMapView({ data }: { data: any[] }) {
    const [selectedItem, setSelectedItem] = useState(undefined);

    return (
      <View style={{ flex: 1, marginBottom: 10, maxWidth: 500 }}>
        <AppMapView data={data} setSelectedItem={setSelectedItem} />
        {selectedItem && (
          <AppModalOverlay
            overlayStyle={{ width: '100%', maxWidth: 500, padding: 20, paddingBottom: 0 }}
            isVisible={true}
            onBackdropPress={() => setSelectedItem(undefined)}
          >
            <ContributionItemRowCarPool
              item={selectedItem}
              getContributionsMode={props.contributionMode}
              navigation={props.navigation}
              user={user}
              onPress={() => props.onPress(selectedItem)}
              contributionType={props.contributionType}
            />
          </AppModalOverlay>
        )}
      </View>
    );
  }

  function containValidContribution(row: any) {
    return props.contributionType !== ContributionType.ALL || resolveContributionType(row) !== ContributionType.UNKNOWN;
  }

  const filteredData = useMemo(() => {
    let first = items.filter((row) => containValidContribution(row));
    let second = first.filter(
      (row: any) =>
        props.contributionType !== ContributionType.LOST_PROPERTY ||
        (showLost ? row.lovIdType === 19 : row.lovIdType === 18)
    );
    let third = second.filter(
      (row: any) =>
        props.contributionType !== ContributionType.ALL ||
        (showReported ? row.contributionSignalement != null && row.contributionSignalement?.length > 0 : true)
    );

    return [...third];
  }, [items, props.contributionType, showReported, showLost]);

  const _onPress = (item: any) => {
    if (props.contributionType === ContributionType.DOCUMENT) {
      const doc = item as ContributionKiosque;
      if (doc.id < 0) {
        setSearch(t('folder') + ':' + doc.tag);
      } else {
        props.onPress(item);
      }
    } else {
      props.onPress(item);
    }
  };

  /*
    Currently, map plugins are not completely compatible with web. Therefore, we disable the map until it is made compatible.
    https://docs.expo.dev/versions/latest/sdk/map-view/
     */
  const mapIsVisible =
    (showMap || (!isMobile() && !isSmallMediumDevice)) && props.contributionType == ContributionType.CARPOOL;
  const listIsVisible = !isMobile() || !showMap;

  const inFolder = props.contributionType === ContributionType.DOCUMENT && search.startsWith(t('folder'));

  const data = useMemo(() => {
    let res: any[];
    if (props.contributionType !== ContributionType.DOCUMENT) {
      res = [...filteredData];
    } else {
      if (search.startsWith(t('folder')) || props.contributionMode === 1) {
        res = filteredData.filter((c) => (c as any).id >= 0);
      } else {
        res = filteredData.filter((c) => (c as any).id < 0);
      }
    }

    if (!!ContributionTypesInfos[props.contributionType].categoryIdSelector && !!selectedCategory) {
      res = res.filter(
        (item) => ContributionTypesInfos[props.contributionType].categoryIdSelector!(item) === selectedCategory.id
      );
    }

    return res;
  }, [filteredData, search, selectedCategory]);

  return (
    <AppScreen>
      <SearchTextInput
        onPressIcon={inFolder ? () => setSearch('') : undefined}
        leftIcon={inFolder ? 'arrow-back' : undefined}
        onChangeText={setSearch}
        value={search}
        style={{ marginBottom: 10 }}
      />

      {false &&
        props.contributionType === ContributionType.COMPANY_NEWS &&
        props.contributionMode === GetContributionsMode.all && (
          <AppListSwitch
            onValueChange={setFilterFavorites}
            value={filterFavorites}
            firstOptionText={t('all')}
            secondOptionText={t('favorites')}
          />
        )}
      {props.contributionModeSecondView === undefined ||
      props.contributionModeSecondView === GetContributionsMode.not_set ? null : (
        <AppListSwitch
          onValueChange={setDisplaySecondView}
          value={displaySecondView}
          firstOptionText={t('all')}
          secondOptionText={t('favorites')}
        />
      )}
      {props.contributionType === ContributionType.LOST_PROPERTY ? (
        <AppListSwitch
          onValueChange={(value) => setShowLost(value)}
          value={showLost}
          firstOptionText={t('found')}
          secondOptionText={t('lost')}
        />
      ) : null}
      {props.contributionType === ContributionType.ALL && props.isAdminView && isAdmin(user) ? (
        <AppListSwitch
          onValueChange={(value) => setShowReported(value)}
          value={showReported}
          firstOptionText={t('all')}
          secondOptionText={t('reported')}
        />
      ) : null}
      {props.contributionType === ContributionType.CARPOOL && isMobile() ? (
        <AppListSwitch
          onValueChange={(value) => setShowMap(value)}
          value={showMap}
          firstOptionText={t('list')}
          secondOptionText={t('map')}
        />
      ) : null}

      {!props.hideCategory && hasCategories(props.contributionType) && (
        <CategoriesList onSelect={setSelectedCategory} categories={categories} />
      )}
      <View style={{ flex: 1, flexDirection: 'row' }}>
        {listIsVisible && (
          <ResponsiveFlatList<any>
            windowSize={1000000}
            maxToRenderPerBatch={100000}
            extraData={isLoading}
            style={{
              marginRight: mapIsVisible ? 10 : 0,
            }}
            // columnWrapperStyle={isMobile() && numColumns !== 1 ? { justifyContent: 'flex-start' } : undefined}
            columnWrapperStyle={{ justifyContent: 'flex-start' }}
            numColumns={props.numColumns}
            contentContainerStyle={
              !isMobile()
                ? {
                    /*flexDirection: props.contributionType == ContributionType.CARPOOL ? 'column' : 'row',
                    maxWidth: listMaxWidth,
                    marginHorizontal: 'auto',*/
                  }
                : {}
            }
            ListEmptyComponent={
              <AppText style={{ textAlign: 'center', color: LIGHT_GRAY }}>{t('no_contributions')}</AppText>
            }
            data={data}
            renderItem={(item) => {
              return props.getRenderItem(item.item, props.contributionMode, () => _onPress(item.item));
            }}
            keyExtractor={(item) => item.id.toString()}
            refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refresh} />}
            scrollEventThrottle={400}
            onEndReached={fetchNext}
          />
        )}
        {mapIsVisible && <AppContributionMapView data={filteredData} />}
      </View>
      {isLoading && (
        <View style={{ padding: 20 }}>
          <ActivityIndicator size="large" color={PRIMARY_COLOR} />
        </View>
      )}
    </AppScreen>
  );
}
