import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Interest } from '../entities/Interest';
import { removeContributionInterest, setContributionInterest } from '../services/api/interest.contributions.api';
import { t } from '../services/translations';
import { alertInfo } from '../services/utils';
import {
  setCurrentContribution,
  setDetailContributionType,
  setForceBack,
  setIsNotif,
  setLoading,
} from '../store/action';
import { ContributionType, ContributionTypesInfos, GetContributionId } from '../types';
import useLoggedUser from './useLoggedUser';
import { setFavoriteNews } from '../services/api/news.contributions.api';

const useContribution = <T extends { id: number; isFavorite: boolean }>(
  type: ContributionType,
  params: { isNotif: boolean | undefined; isAdminView: boolean | undefined } | undefined,
  fetcher: () => Promise<T>,
  getInterestedList: (data: T) => Interest[]
) => {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const [data, setData] = useState<T | undefined>();
  const [visible, setVisible] = useState(false);
  const [showInterestedPeople, setShowInterestedPeople] = useState(false);
  const [zoomableImageVisible, setZoomableImageVisible] = useState(false);
  const [interestId, setInterestId] = useState<number | undefined>(undefined);
  const [interested, setInterested] = useState<Interest[]>([]);
  const [locked, setLocked] = useState(false);

  const reload = () => {
    dispatch(setLoading(true));
    fetcher().then((data) => {
      setData(data);
      dispatch(setCurrentContribution(data));
      const interested = getInterestedList(data);
      setInterestId(interested.find((x) => x.userId === user.id)?.id);
      setInterested(interested);
      dispatch(setLoading(false));
    });

    dispatch(setDetailContributionType(type));
    if (!!params?.isNotif) dispatch(setIsNotif(true));
    if (!!params?.isAdminView) dispatch(setForceBack(true));

    return () => {
      dispatch(setDetailContributionType(undefined));
      dispatch(setIsNotif(false));
      dispatch(setForceBack(false));
    };
  };

  useEffect(reload, []);

  const toggleInterest = () => {
    if (!data || locked) {
      return;
    }

    setLocked(true);
    setLoading(true);

    if (!interestId) {
      setContributionInterest(
        user,
        GetContributionId(type, data),
        data.id,
        type,
        ContributionTypesInfos[type].titleSelector?.(data) ?? ''
      )
        .then((r) => r.data)
        .then((v) => {
          setInterested((interested) => [
            ...interested,
            { id: v.id, date: new Date().toISOString(), user, userId: user.id },
          ]);
          setInterestId(v.id);
        })
        .catch(() => alertInfo(t('error_occurred')))
        .finally(() => {
          dispatch(setLoading(false));
          setLocked(false);
        });
    } else {
      removeContributionInterest(user, interestId, type)
        .then(() => setInterested((interested) => interested.filter((x) => x.id !== interestId)))
        .catch(() => alertInfo(t('error_occurred')))
        .finally(() => {
          dispatch(setLoading(false));
          setInterestId(undefined);
          setLocked(false);
        });
    }
  };

  const toggleFavorite = () => {
    if (!data || locked) {
      return;
    }

    setLocked(true);
    setLoading(true);
    setFavoriteNews(user, data.id, { favorite: !data.isFavorite })
      .then((r) => r.data)
      .then(() => {
        setData({ ...data, isFavorite: !data.isFavorite });
      })
      .catch(() => alertInfo(t('error_occurred')))
      .finally(() => {
        dispatch(setLoading(false));
        setLocked(false);
      });
  };

  return {
    reload,
    data: data as T,
    loading: !data,
    visible,
    setVisible,
    interest: {
      show: showInterestedPeople,
      set: setShowInterestedPeople,
      toggle: toggleInterest,
      isInterested: !!interestId,
      interested,
    },
    zoom: {
      visible: zoomableImageVisible,
      setVisible: setZoomableImageVisible,
    },
    toggleFavorite,
  };
};

export default useContribution;
